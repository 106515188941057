import Router from "./route/Index";

import ThemeProvider from "./layout/provider/Theme";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <ThemeProvider>
      <Router />
      <ToastContainer
                // hideProgressBar
                autoClose={1200}
            />
    </ThemeProvider>
  );
};
export default App;