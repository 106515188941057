import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";

import FileManagerProvider from "../pages/app/file-manager/components/Context";
import { getCookie } from "../utils/Utils";
import Cookies from 'js-cookie';

const Layout = ({title, app, ...props}) => { 
  const [loading, setLoading] = useState(true);
  const [role, setRoles] = useState([]);
  const userRole = role;
  
  useEffect(() => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
    }
  }, []);

  //New 
  useEffect(() => {
    const fetchBlogsFromAPI = async () => {
      try {
        const authToken = getCookie("authToken");

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/public/get-role`, {
          headers: {
            authToken: authToken,
          },
          method: "POST",
        });

        if (response.ok) {
          const responseData = await response.json();
            if (responseData.status) {
              const roleId = responseData.data.role.id;
              console.log(typeof roleId);
              setRoles(roleId);
            } else {
          }
        } else {
            console.log("Error fetching blogs:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
        // throw error;
      } finally {
        setLoading(false); 
      }
    };
    fetchBlogsFromAPI();
  }, []);
  

  const [authChecking, setAuthChecking] = useState(true);

  useEffect(() => {
    let at = Cookies.get("authToken");
    if (at && userRole === 3) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      setAuthChecking(false);
      Cookies.remove("currentURL", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    }
  }, [role]);
  // if (authChecking) return <>Redirecting to authentication</>;
  return (
      <FileManagerProvider>
        <Head title={'Admin Panel of Finance'} />
        <AppRoot>
          <Sidebar fixed />
          <AppMain>
            <AppWrap className={'ps-lg-4 ms-lg-5'}>
              <Header fixed  />
              <Outlet />
              <Footer />
            </AppWrap>
          </AppMain>
        </AppRoot>
      </FileManagerProvider>
  );
};
export default Layout;
