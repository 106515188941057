import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner, Card, Row, Col, CardTitle, CardText, CardBody } from 'reactstrap';
import Content from '../layout/content/Content';
import { postRequest } from '../api-service';
import { PreviewCard } from '../components/Component';

const UserDetailsPage = () => {
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState(null);
    const { userId } = useParams();

    useEffect(() => {
        fetchUserDetails();
    }, [userId]);

    const fetchUserDetails = async () => {
        try {
            const response = await postRequest('finance/get-user-details', { user_id: userId });
            setUserDetails(response.data.details);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user details:', error);
            setLoading(false);
        }
    };

    if (loading) {
        return <div className='d-flex justify-content-center my-auto'><Spinner color="primary" /></div>;
    }

    if (!userDetails) {
        return <div>No user details found.</div>;
    }

    const getNestedProperty = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    const displayLivingStatus = (value) => {
        return value === '0' ? 'Own' : value === '1' ? 'Rent' : 'N/A';
    };

    const displayPaymentStructureStatus = (value) => {
        return value === '0' ? 'Hourly' : value === '1' ? 'Salary':value === '2' ? 'Other' : 'N/A';
    };

    return (
        <Content>
            {/* <Card className="card-preview"> */}
                <h3 className='text-center m-2'>User Details</h3>
                <PreviewCard>
                    <Row className="g-gs">
                        <Col sm="3">
                            <Card className="card-bordered" color="light">
                            <CardBody className="card-inner">
                                <CardTitle tag="h5">Personal Details</CardTitle>
                                <CardText>
                                <p>Name: {getNestedProperty(userDetails, 'first_name') || 'N/A'} {getNestedProperty(userDetails, 'last_name') || 'N/A'}</p>
                                <p>Email: {getNestedProperty(userDetails, 'email') || 'N/A'}</p>
                                <p>Phone: {getNestedProperty(userDetails, 'phone') || 'N/A'}</p>
                                <p>Date of Birth: {getNestedProperty(userDetails, 'dob') || 'N/A'}</p>
                                <p>SSN: {getNestedProperty(userDetails, 'SSL') || 'N/A'}</p>
                                <p>Relation: {getNestedProperty(userDetails, 'relationship') || 'N/A'}</p>
                                <p>Relative Name: {getNestedProperty(userDetails, 'relative_name') || 'N/A'}</p>
                                <p>Address: {getNestedProperty(userDetails, 'streetaddress') || 'N/A'}, {getNestedProperty(userDetails, 'streetaddress2') || 'N/A'}, {getNestedProperty(userDetails, 'city') || 'N/A'}, {getNestedProperty(userDetails, 'state') || 'N/A'} {getNestedProperty(userDetails, 'zip') || 'N/A'}</p>
                                <p>Country: {getNestedProperty(userDetails, 'country') || 'N/A'}</p>
                                <p>Living Situation: {displayLivingStatus(getNestedProperty(userDetails, 'living'))}</p>
                                <p>Rent: ${getNestedProperty(userDetails, 'rent') || 'N/A'}</p>
                                <p>How Long: {getNestedProperty(userDetails, 'how_long') || 'N/A'}</p>
                                </CardText>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col sm="3">
                            <Card className="card-bordered" color="light">
                            <CardBody className="card-inner">
                                <CardTitle tag="h5">Employment Details</CardTitle>
                                <CardText>
                                <p>Current Employer: {getNestedProperty(userDetails, 'current_employer') || 'N/A'}</p>
                                <p>Employer Phone: {getNestedProperty(userDetails, 'emp_phone') || 'N/A'}</p>
                                <p>Employer Email: {getNestedProperty(userDetails, 'emp_email') || 'N/A'}</p>
                                <p>Employer Living: {displayLivingStatus(getNestedProperty(userDetails, 'emp_living'))}</p>
                                <p>Employer Position: {getNestedProperty(userDetails, 'position') || 'N/A'}</p>
                                <p>Employer Fax Number: {getNestedProperty(userDetails, 'emp_fax_number') || 'N/A'}</p>
                                <p>Employer Address: {getNestedProperty(userDetails, 'emp_streetaddress') || 'N/A'}, {getNestedProperty(userDetails, 'emp_streetaddress2') || 'N/A'}, {getNestedProperty(userDetails, 'emp_city') || 'N/A'}, {getNestedProperty(userDetails, 'emp_state') || 'N/A'} {getNestedProperty(userDetails, 'emp_zip') || 'N/A'}</p>
                                <p>Payment Structure: {displayPaymentStructureStatus(getNestedProperty(userDetails, 'payment_structure'))}</p>
                                <p>Annual Income: ${getNestedProperty(userDetails, 'annual_income') || 'N/A'}</p>
                                <p>Previous Employer: {getNestedProperty(userDetails, 'previous_employer') || 'N/A'}</p>
                                <p>How Long Employed: {getNestedProperty(userDetails, 'how_long_employed') || 'N/A'}</p>
                                </CardText>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col sm="3">
                        <Card className="card-bordered" color="light">
                            <CardBody className="card-inner">
                                <CardTitle tag="h5">Co-Applicant Details</CardTitle>
                                <CardText>
                                <p>Co-Applicant Name: {getNestedProperty(userDetails, 'coaplicant_first_name') || 'N/A'} {getNestedProperty(userDetails, 'last_name') || 'N/A'}</p>
                                <p>Co-Applicant Phone: {getNestedProperty(userDetails, 'coaplicant_phone') || 'N/A'}</p>
                                <p>Co-Applicant Email: {getNestedProperty(userDetails, 'coaplicant_email') || 'N/A'}</p>
                                <p>Co-Applicant Living: {displayLivingStatus(getNestedProperty(userDetails, 'coaplicant_living'))}</p>
                                <p>Co-Applicant SSN Number: {getNestedProperty(userDetails, 'coaplicant_ssn_number') || 'N/A'}</p>
                                <p>Co-Applicant DOB: {getNestedProperty(userDetails, 'coaplicant_dob') || 'N/A'}</p>
                                <p>Co-Applicant Address: {getNestedProperty(userDetails, 'coaplicant_streetaddress') || 'N/A'}, {getNestedProperty(userDetails, 'coaplicant_streetaddress2') || 'N/A'}, {getNestedProperty(userDetails, 'coaplicant_city') || 'N/A'}, {getNestedProperty(userDetails, 'coaplicant_state') || 'N/A'} {getNestedProperty(userDetails, 'coaplicant_zip') || 'N/A'}</p>
                                <p>Co-Applicant Rent: {getNestedProperty(userDetails, 'coaplicant_rent') || 'N/A'}</p>
                                <p>Co-Applicant How Long: ${getNestedProperty(userDetails, 'coaplicant_how_long') || 'N/A'}</p>
                                </CardText>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col sm="3">
                            <Card className="card-bordered" color="light">
                            <CardBody className="card-inner">
                                <CardTitle tag="h5">Last Step</CardTitle>
                                <CardText>
                                <p>Merchant Name: {getNestedProperty(userDetails, 'name_of_merchant') || 'N/A'}</p>
                                <p>Merchant Code: {getNestedProperty(userDetails, 'merchant_code') || 'N/A'}</p>
                                <p>Date of Applicant: {getNestedProperty(userDetails, 'date_for_applicant') || 'N/A'}</p>
                                <p>Date of co-Applicant: {getNestedProperty(userDetails, 'date_for_coapplicant') || 'N/A'}</p>
                                <p>Signature of Applicant: {getNestedProperty(userDetails, 'applicant_signature') ? <img src={`${getNestedProperty(userDetails, 'applicant_signature')}`}  /> : 'N/A'}</p>
                                <p>Signature of co-Applicant: {getNestedProperty(userDetails, 'coapplicant_signature') ? <img src={`${getNestedProperty(userDetails, 'coapplicant_signature')}`}  /> : 'N/A'}</p>
                                </CardText>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col sm="3">
                            <Card className="card-bordered" color="light">
                            <CardBody className="card-inner">
                                <CardTitle tag="h5">Loan Information</CardTitle>
                                <CardText>
                                <p>Account Number: {getNestedProperty(userDetails, 'account_number') || 'N/A'}</p>
                                <p>Address: {getNestedProperty(userDetails, 'mortgage_streetaddress') || 'N/A'}, {getNestedProperty(userDetails, 'mortgage_streetaddress2') || 'N/A'}, {getNestedProperty(userDetails, 'mortgage_city') || 'N/A'}, {getNestedProperty(userDetails, 'mortgage_state') || 'N/A'} {getNestedProperty(userDetails, 'mortgage_zip') || 'N/A'}</p>
                                </CardText>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col sm="3">
                            <Card className="card-bordered" color="light">
                            <CardBody className="card-inner">
                                <CardTitle tag="h5">Other Loans Information</CardTitle>
                                <CardText>
                                <p>Other Loans Account Number: {getNestedProperty(userDetails, 'other_loans_account_number') || 'N/A'}</p>
                                <p>Other Loans Amount: {getNestedProperty(userDetails, 'other_loans_amount') || 'N/A'}</p>
                                <p>Other Loans Description: {getNestedProperty(userDetails, 'other_loans_description') || 'N/A'}</p>
                                </CardText>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col sm="3">
                        <Card className="card-bordered" color="light">
                            <CardBody className="card-inner">
                                <CardTitle tag="h5">Auto Loans Information</CardTitle>
                                <CardText>
                                <p>Auto Loans Account Number : {getNestedProperty(userDetails, 'auto_loans_account_number') || 'N/A'}</p>
                                <p>Auto Loans Amount: {getNestedProperty(userDetails, 'auto_loans_amount') || 'N/A'}</p>
                                <p>Auto Loans Description : {getNestedProperty(userDetails, 'auto_loans_description') || 'N/A'}</p>
                                </CardText>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col sm="3">
                            <Card className="card-bordered" color="light">
                            <CardBody className="card-inner">
                                <CardTitle tag="h5">Credit Card details</CardTitle>
                                <CardText>
                                <p>Name of Card: {getNestedProperty(userDetails, 'name') || 'N/A'}</p>
                                <p>Account Number: {getNestedProperty(userDetails, 'account_number') || 'N/A'}</p>
                                <p>Current Balance: {getNestedProperty(userDetails, 'current_balance') || 'N/A'}</p>
                                <p>Monthly Payment: {getNestedProperty(userDetails, 'monthly_payment') || 'N/A'}</p>
                                </CardText>
                            </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </PreviewCard>
            {/* </Card> */}
        </Content>
    );
};

export default UserDetailsPage;
