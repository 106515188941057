// import { Button } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { Button, Card, Input, Label, Spinner, Table } from 'reactstrap';
import Content from '../layout/content/Content'
import { useNavigate } from 'react-router';
import { postRequest } from '../api-service';
import { FaEye, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const UserInfo = () => {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [deleteData, setdeleteData] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserData();
    }, [deleteData===true]);

    const fetchUserData = async () => {
        try {
            const response = await postRequest('finance/get-user-details', {});
            
            if (Array.isArray(response.data.details)) {
                setUserData(response.data.details);
            } else {
                console.error('Unexpected response structure:', response);
                setUserData([]);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setLoading(false);
        }
    };

    const handleViewDetails = (userId) => {
        navigate(`/user-details/${userId}`);
    };

    // const handleDeleteUser = async (userId) => {
    //     console.log('Delete user with ID:', userId);
    //     const data = new FormData();
    //     data.append("user_id", userId);
    //     try {
            
    //         const response = await postRequest('finance/delete-form-details', data);

    //         if (response.status) {
    //             toast.success(response.message);
    //         setdeleteData(true);

    //         }

    //         const result = await response.json();
    //         toast.success(result.message);
            
    //         return result;
    //     } catch (error) {
    //         console.error('Error saving form data:', error);
    //         console.error('Error details:', error.message);
    //         throw error;
    //     }
    // };
    const handleDeleteUser = async (userId) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            const data = new FormData();
            data.append("user_id", userId);

            try {
                const response = await postRequest('finance/delete-form-details', data);

                if (response.status) {
                    toast.success(response.message);
                    setdeleteData(!deleteData);  
                } else {
                    toast.error(response.message);
                }
            } catch (error) {
                console.error('Error deleting user:', error);
                toast.error('Error deleting user');
            }
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const year = date.getFullYear().toString().slice(-4); 
        return `${day}-${month}-${year}`;
    };

    return (
        <Content>
            <div>
                <Card className="card-preview border-0">
                    <div className="d-flex justify-content-between">
                        <Input
                            type="search"
                            placeholder="Search by name"
                            style={{ width: "20%", margin: "10px 0" }}
                        />
                        <div className="d-flex align-items-center">
                            <Label className="me-2 mb-0">Show</Label>
                            <Input
                                type="select"
                                style={{ width: "fit-content", height: "fit-content" }}
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </Input>
                        </div>
                    </div>
                    <div style={{ overflowX: "auto" }}>
                        <Table className="all-table" striped style={{ minWidth: "100%", textAlign: "center", border: "0.5px solid #d5d7db" }} >
                            <thead>
                                <tr>
                                    <th className="border-end">Sr. No.</th>
                                    <th className="border-end">Name</th>
                                    <th className="border-end">Email</th>
                                    <th className="border-end">Phone Number</th>
                                    <th className="border-end">Date of Birth</th>
                                    <th className="border-end">Submitted At</th>
                                    <th className="border-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="6">
                                            <Spinner style={{ margin: "0 auto" }} type="grow" color="danger">Loading...</Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    userData.map((user, index) => (
                                        <tr key={user.id}>
                                            <td className="border-end">{index + 1}</td>
                                            <td className="border-end" style={{ textAlign: "left", maxWidth: "200px" }}>
                                                {`${user.first_name || ""} ${user.last_name || ""}`}
                                            </td>
                                            <td className="border-end" style={{ textAlign: "left", maxWidth: "200px" }}>
                                                <a href={`mailto:${user.email}`}>{user.email || "N/A"}</a>
                                            </td>
                                            <td className="border-end text-center" style={{ maxWidth: "100px" }}>
                                                <a href={`tel:${user.phone}`}>{user.phone || "N/A"}</a>
                                            </td>
                                            <td className="border-end text-center" style={{ maxWidth: "200px" }}>
                                                {user.dob || "N/A"}
                                            </td>
                                            <td className="border-end text-center" style={{ maxWidth: "200px" }}>
                                                {formatDate(user.created_at)}
                                            </td>
                                            <td className="border-end text-center" style={{ maxWidth: "200px" }}>
                                                <Button className="fs-20px" color="link" onClick={() => handleViewDetails(user.user_id)}>
                                                    <FaEye /> 
                                                </Button>
                                                <Button className="fs-17px" color="link" onClick={() => handleDeleteUser(user.user_id)}>
                                                    <FaTrash color="red" /> 
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </div>
                </Card>
            </div>
        </Content>
    );
};

export default UserInfo;
